* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:root {
  font-family: 'Raleway', sans-serif;
}

section {
  padding: 10px 0 15px 0;
  width: 90%;
  margin: 0 auto;
}

.section-heading {
  font-size: 2.3rem;
  font-weight: 300;
  color: #383838;
  text-align: center;
  margin-bottom: 20px;
}

.section-container {
  background-color: #f7f7f7 ;
  padding: 20px 10px;
}

.section-title {
  color: #383838;
  font-size: 1.4rem;
  font-weight: 300;
  padding: 5px 0 15px 0;
  text-align: center;
  text-transform: uppercase;
}

.section-text {
  color: #6A6C6E;
  line-height: 22px;
  margin-bottom: 10px;
  font-weight: 300;
  text-align: center;
}

.section-container a {
  text-decoration: none;
  line-height: 22px;
  font-weight: 300;
  margin-bottom: 10px;
  color: #6A6C6E;
}

.section-container a:hover{
  color: black;
}

.section-container ul {
  color: #6A6C6E;
  font-weight: 300;
}

section img, section iframe {
  box-shadow: 5px 5px 10px 0px rgba(0,0,0,0.4);
}

figure {
  margin-bottom: 10px;
}

q {
  font-style: italic;
}

@media (min-width: 960px) {
  section {
    width: 85%;
    max-width: 1300px;
    padding: 40px 0 20px 0;
  }
  .section-container {
    padding: 30px 40px;
  }
  .section-title {
    text-align: left;
  }
  .section-text {
    line-height: 30px;
    text-align: left;
    margin-bottom: 15px;
  }
}