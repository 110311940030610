.header-container {
  width: 90%;
  margin: 0 auto;
}

@media (min-width: 960px) {
  .header-container {
    width: 85%;
    max-width: 1300px;
  }
}