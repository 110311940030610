.iframe-cont {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; 
  height: 0;
  margin-bottom: 10px;
}

.iframe-cont iframe {
  position: absolute;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media (min-width: 1060px) {
  .composer-projects {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 25px;
  }
}